import React, { Component } from 'react';
import './Navbar.css'
import {NavbarItems} from './NavbarItems';
import {NavLink} from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';

class Navbar extends Component {
    state = {
        clicked: false
    }

    render() {
        return(
            <nav className="navbar">
                <div className="navbar-brand">
                    <NavLink className="navbar-logo" to="/">MP</NavLink>
                </div>
                <ul className='nav-menu'>
                    {NavbarItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <a target='_blank' rel='noreferrer' title={item.title} className={item.cName} href={item.url}>
                                    <i className={item.iconName}></i>
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        );
    }
}

export default Navbar;