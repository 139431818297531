export const NavbarItems = [
    {
        iconName: 'bi bi-reddit',
        title: 'Reddit',
        url: 'https://www.reddit.com/r/medpossible',
        cName: 'nav-links' 
    },
    {
        iconName: 'bi bi-tiktok',
        title: 'TikTok',
        url: 'https://www.tiktok.com/@medpossible',
        cName: 'nav-links' 
    },
    {
        iconName: 'bi bi-pinterest',
        title: 'Pinterest',
        url: 'https://www.pinterest.com/medpossible/',
        cName: 'nav-links' 
    },
    {
        iconName: 'bi bi-twitter',
        title: 'Twitter',
        url: 'https://www.twitter.com/medpossible',
        cName: 'nav-links' 
    },
]