import React, { Component } from 'react';
import './Home.css';

class App extends Component {

    constructor() {
        super();

        document.title = 'MedPossible';
    }

    render() {
        return (
            <div className='home'>
                <h1 className='med-possible-branding'>MedPossible</h1>
                <div className='coming-soon'>
                    <h1 className='coming-soon-label'>COMING SOON</h1>
                    <h1 className='coming-soon-date'>9/15/22</h1>
                </div>
            </div>
        );
    }
}

export default App;